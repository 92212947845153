<template>
<div class="bg-grey">
    <div class="we">
       <myMenu></myMenu>
       <div class="myBody"><router-view></router-view></div>
    </div>   
</div> 
</template>
<script>
import myMenu from '@/components/myMenu'
export default {
    data(){
        return{

        }
    },
    components:{
        myMenu
    }
}
</script>
<style lang="scss" scoped>
.bg-grey{
    background-color: #F6F6F9;
    .we{
    padding: 60px 20px;
    box-sizing: border-box;
    width: 1120px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .menu{
        background-color: #fff;
        max-height: 323px;
    }
    .myBody{
        width: 800px;
    }
}
}

</style>
