<template>
  <ul class="menu">
    <router-link tag="li" :to="{ name: 'aboutUs'}">关于我们</router-link>
    <!-- <router-link tag="li" :to="{name:'brand'}">品牌简介</router-link> -->
    <router-link tag="li" :to="{name:'contactUs'}">联系我们</router-link>
    <router-link tag="li" :to="{name:'joinUs'}">加入我们</router-link>
    <router-link tag="li" :to="{name:'aggreement'}">用户协议</router-link>
    <!-- <li>常见问题</li> -->
  </ul>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.menu {
  list-style: none;
  box-shadow: 0px 16px 40px 0px rgba(178, 178, 188, 0.26);
  border-radius: 12px;
  width: 240px;
  li {
    font-size: 20px;
    color: #444144;
    height: 80px;
    line-height: 80px;
    cursor: pointer;
    &:first-child{
      border-radius: 12px 12px 0 0;
    }
    &:last-child{
      border-radius: 0 0 12px 12px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba(238, 238, 238, 1);
    }
  }
  .router-link-active {
    background-color: rgba(255, 112, 65, 1);
    color: #fff;
  }
}
</style>
